/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    strong: "strong"
  }, _provideComponents(), props.components), {Item, Warning} = _components;
  if (!Item) _missingMdxReference("Item", true);
  if (!Warning) _missingMdxReference("Warning", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "This sheet is used to give quick access to the most optimal sigils and potions for each fractal in an average group for power builds. However some fractals have small variations where different parts of the fractal benefit from different potions or specific sigils such as ", React.createElement(Item, {
    id: "84505",
    id: "84505"
  }), " or ", React.createElement(Item, {
    id: "72872",
    id: "72872"
  }), ". For further information consult the individual ", React.createElement(_components.a, {
    href: "/fractals"
  }, "Fractal guides"), " and ", React.createElement(_components.a, {
    href: "/guides/cm-profession-guides"
  }, "class specific CM guides"), "."), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Condition classes should use the sigils and utilities that are mentioned in the build pages.")), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Fractal"), React.createElement(_components.th, null, "Day/Night"), React.createElement(_components.th, null, "Sigil & Potion"), React.createElement(_components.th, null, "Description"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/lonely-tower"
  }, "Lonely Tower")), React.createElement(_components.td, null, "Day"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24664",
    size: "large",
    disableText: true,
    id: "24664"
  }), " ", React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "8886",
    size: "large",
    disableText: true,
    id: "8886"
  })), React.createElement(_components.td, null, "Both ", React.createElement(Item, {
    id: "24664",
    id: "24664"
  }), " and ", React.createElement(Item, {
    id: "8886",
    id: "8886"
  }), " will only work on Eparch and Kryptis adds. For Deimos and Cerus use  ", React.createElement(Item, {
    id: "9443",
    id: "9443"
  }), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/silent-surf"
  }, "Silent Surf")), React.createElement(_components.td, null, "Day"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24868",
    size: "large",
    disableText: true,
    id: "24868"
  }), " ", React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "9443",
    size: "large",
    disableText: true,
    id: "9443"
  })), React.createElement(_components.td, null, "None")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/sunqua-peak"
  }, "Sunqua Peak")), React.createElement(_components.td, null, "Day (T4) / Night (CM)"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "36053",
    size: "large",
    disableText: true,
    id: "36053"
  }), " ", React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "9443",
    size: "large",
    disableText: true,
    id: "9443"
  })), React.createElement(_components.td, null, "On normal mode swap ", React.createElement(Item, {
    id: "36053",
    id: "36053"
  }), " for ", React.createElement(Item, {
    id: "24868",
    id: "24868"
  }))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/shattered-observatory"
  }, "Shattered Observatory")), React.createElement(_components.td, null, "Day"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "24868",
    size: "large",
    disableText: true,
    id: "24868"
  }), " ", React.createElement(Item, {
    id: "9443",
    size: "large",
    disableText: true,
    id: "9443"
  })), React.createElement(_components.td, null, "When at Arkk swap to ", React.createElement(Item, {
    id: "50082",
    id: "50082"
  }), ". Although the fractal switches to night after Skorvald, ", React.createElement(Item, {
    id: "36053",
    id: "36053"
  }), " ", React.createElement(_components.strong, null, "does not work!"))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/nightmare"
  }, "Nightmare")), React.createElement(_components.td, null, "Day"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "24868",
    size: "large",
    disableText: true,
    id: "24868"
  }), " ", React.createElement(Item, {
    id: "50082",
    size: "large",
    disableText: true,
    id: "50082"
  })), React.createElement(_components.td, null, "Right after MAMA swap to ", React.createElement(Item, {
    id: "24658",
    id: "24658"
  }))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/chaos-isles"
  }, "Chaos Isles")), React.createElement(_components.td, null, "Day"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "24868",
    size: "large",
    disableText: true,
    id: "24868"
  }), " ", React.createElement(Item, {
    id: "9443",
    size: "large",
    disableText: true,
    id: "9443"
  })), React.createElement(_components.td, null, "None")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/aetherblade"
  }, "Aetherblade")), React.createElement(_components.td, null, "Day"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "24868",
    size: "large",
    disableText: true,
    id: "24868"
  }), " ", React.createElement(Item, {
    id: "50082",
    size: "large",
    disableText: true,
    id: "50082"
  })), React.createElement(_components.td, null, "Swap to ", React.createElement(Item, {
    id: "24672",
    id: "24672"
  }), " and ", React.createElement(Item, {
    id: "8887",
    id: "8887"
  }), " for the endboss.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/captain-mai-trin-boss"
  }, "Captain Mai Trin")), React.createElement(_components.td, null, "Day"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "24868",
    size: "large",
    disableText: true,
    id: "24868"
  }), " ", React.createElement(Item, {
    id: "50082",
    size: "large",
    disableText: true,
    id: "50082"
  })), React.createElement(_components.td, null, "Use a ", React.createElement(Item, {
    id: "78978",
    id: "78978"
  }), " to prestack at the mistlock and port right to the boss")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/cliffside"
  }, "Cliffside")), React.createElement(_components.td, null, "Day"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "24678",
    size: "large",
    disableText: true,
    id: "24678"
  }), " ", React.createElement(Item, {
    id: "8881",
    size: "large",
    disableText: true,
    id: "8881"
  })), React.createElement(_components.td, null, "None")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/snowblind"
  }, "Snowblind")), React.createElement(_components.td, null, "Night"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "36053",
    size: "large",
    disableText: true,
    id: "36053"
  }), " ", React.createElement(Item, {
    id: "24667",
    size: "large",
    disableText: true,
    id: "24667"
  }), " ", React.createElement(Item, {
    id: "8883",
    size: "large",
    disableText: true,
    id: "8883"
  })), React.createElement(_components.td, null, "None")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/volcanic"
  }, "Volcanic")), React.createElement(_components.td, null, "Night"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "36053",
    size: "large",
    disableText: true,
    id: "36053"
  }), " ", React.createElement(Item, {
    id: "24648",
    size: "large",
    disableText: true,
    id: "24648"
  }), " ", React.createElement(Item, {
    id: "8890",
    size: "large",
    disableText: true,
    id: "8890"
  })), React.createElement(_components.td, null, "For the endboss swap ", React.createElement(Item, {
    id: "24648",
    id: "24648"
  }), " to ", React.createElement(Item, {
    id: "24664",
    id: "24664"
  }), " and ", React.createElement(Item, {
    id: "8886",
    id: "8886"
  }))), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/uncategorized"
  }, "Uncategorized")), React.createElement(_components.td, null, "Day"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "24868",
    size: "large",
    disableText: true,
    id: "24868"
  }), " ", React.createElement(Item, {
    id: "8887",
    size: "large",
    disableText: true,
    id: "8887"
  })), React.createElement(_components.td, null, "Swap your ", React.createElement(Item, {
    id: "24615",
    id: "24615"
  }), " sigil to ", React.createElement(Item, {
    id: "24672",
    id: "24672"
  }), " once you reach Old Tom")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/molten-boss"
  }, "Molten Boss")), React.createElement(_components.td, null, "Day"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "24868",
    size: "large",
    disableText: true,
    id: "24868"
  }), " ", React.createElement(Item, {
    id: "50082",
    size: "large",
    disableText: true,
    id: "50082"
  })), React.createElement(_components.td, null, "None")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/swampland"
  }, "Swampland")), React.createElement(_components.td, null, "Night"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "36053",
    size: "large",
    disableText: true,
    id: "36053"
  }), " ", React.createElement(Item, {
    id: "24868",
    size: "large",
    disableText: true,
    id: "24868"
  }), " ", React.createElement(Item, {
    id: "9443",
    size: "large",
    disableText: true,
    id: "9443"
  })), React.createElement(_components.td, null, "None")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/twilight-oasis"
  }, "Twilight Oasis")), React.createElement(_components.td, null, "Night"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "36053",
    size: "large",
    disableText: true,
    id: "36053"
  }), " ", React.createElement(Item, {
    id: "9443",
    size: "large",
    disableText: true,
    id: "9443"
  })), React.createElement(_components.td, null, "None")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/urban-battlegrounds"
  }, "Urban Battlegrounds")), React.createElement(_components.td, null, "Day"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "24868",
    size: "large",
    disableText: true,
    id: "24868"
  }), " ", React.createElement(Item, {
    id: "9443",
    size: "large",
    disableText: true,
    id: "9443"
  })), React.createElement(_components.td, null, "None")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/thaumanova-reactor"
  }, "Thaumanova Reactor")), React.createElement(_components.td, null, "Day"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "24868",
    size: "large",
    disableText: true,
    id: "24868"
  }), " ", React.createElement(Item, {
    id: "9443",
    size: "large",
    disableText: true,
    id: "9443"
  })), React.createElement(_components.td, null, "None")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/deepstone"
  }, "Deepstone")), React.createElement(_components.td, null, "Day"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "24868",
    size: "large",
    disableText: true,
    id: "24868"
  }), " ", React.createElement(Item, {
    id: "9443",
    size: "large",
    disableText: true,
    id: "9443"
  })), React.createElement(_components.td, null, "None")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/molten-furnace"
  }, "Molten Furnace")), React.createElement(_components.td, null, "Day"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "24868",
    size: "large",
    disableText: true,
    id: "24868"
  }), " ", React.createElement(Item, {
    id: "50082",
    size: "large",
    disableText: true,
    id: "50082"
  })), React.createElement(_components.td, null, "None")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/underground-facility"
  }, "Underground Facility")), React.createElement(_components.td, null, "Night"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "36053",
    size: "large",
    disableText: true,
    id: "36053"
  }), " ", React.createElement(Item, {
    id: "24684",
    size: "large",
    disableText: true,
    id: "24684"
  }), " ", React.createElement(Item, {
    id: "8892",
    size: "large",
    disableText: true,
    id: "8892"
  })), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24661",
    id: "24661"
  }), " and ", React.createElement(Item, {
    id: "8885",
    id: "8885"
  }), " if the final boss is the Ice Elemental")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/solid-ocean"
  }, "Solid Ocean")), React.createElement(_components.td, null, "Night"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "36053",
    size: "large",
    disableText: true,
    id: "36053"
  }), " ", React.createElement(Item, {
    id: "24661",
    size: "large",
    disableText: true,
    id: "24661"
  }), " ", React.createElement(Item, {
    id: "8885",
    size: "large",
    disableText: true,
    id: "8885"
  })), React.createElement(_components.td, null, "None")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/sirens-reef"
  }, "Siren's Reef")), React.createElement(_components.td, null, "Day"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "24868",
    size: "large",
    disableText: true,
    id: "24868"
  }), " ", React.createElement(Item, {
    id: "9443",
    size: "large",
    disableText: true,
    id: "9443"
  })), React.createElement(_components.td, null, "None")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.a, {
    href: "/fractals/aquatic-ruins"
  }, "Aquatic Ruins")), React.createElement(_components.td, null, "Day"), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24615",
    size: "large",
    disableText: true,
    id: "24615"
  }), " ", React.createElement(Item, {
    id: "24658",
    size: "large",
    disableText: true,
    id: "24658"
  }), " ", React.createElement(Item, {
    id: "50082",
    size: "large",
    disableText: true,
    id: "50082"
  })), React.createElement(_components.td, null, React.createElement(Item, {
    id: "24658",
    id: "24658"
  }), " does not work against the main boss of this fractal. Instead, it works on the split phases and adds as well as the krait on the way to the main boss arena.")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
